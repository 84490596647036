import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import moment from "moment";
import { Grid, Paper, TextField, Autocomplete, Button } from "@mui/material";


import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";



export default function ViewIaf() {
  const printComponentRef = React.useRef();
  const params = useParams();
  const [clientArray, setClientArray] = React.useState([]);
  const [srfInstruments, setSrfInstruments] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  var [allInstruments, setInstruments] = React.useState([]);
  const [letterHead, setLetterHead] = React.useState(true);
  // const [electronicSign, setElectronicSign] = React.useState(true);

  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [commitedDate, setCommitedDate] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [clientID, setClientID] = React.useState("");
  const [companyData, setCompanyData] = React.useState({
    companyName: '',
    address: '',
  });
  const [downloadPdfloading, setDownlaodPdfLoading] =React.useState(false);
  const [newAddressArray, setNewAddressArray]=React.useState([]);
  const [disciplineName, setDisciplineName] = React.useState("");
  const [iafNo, setIafNo] = React.useState("");


  function fetchSrfDetails() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfs?_where=(id,eq,${params.id})`)
      .then((res) => {
        setServiceReqNumber(res.data[0]?.serviceReqNumber);
        setDcDate(res.data[0]?.dcDate);
        setReceiptDate(res.data[0]?.receiptDate);
        setEntryDate(res.data[0]?.entryDate);
        setCommitedDate(res.data[0]?.committedDeliveryDate);
        setDcNumber(res.data[0]?.dcNumber);
        setClientID(res.data[0]?.clientId);
        setNewAddressArray(res.data[0]?.contactPersonDetails ? JSON.parse(res.data[0]?.contactPersonDetails) : null);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }

  const getClientId = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clients?_where=(id,eq,${clientID})`)
      .then((res) => {
        setClientArray(res.data);
        setCompanyData({
          companyName: res.data[0]?.companyName,
          address: res.data[0]?.address,
        });
      })
      .catch((error) => {
        console.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      // .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})~and(location,eq,1)`)
      .post(url + `dynamic`, {query :`SELECT DISTINCT * FROM srfInstruments si JOIN instruments ins ON si.instrumentId = ins.id JOIN discipline dis ON dis.id = ins.disciplineId WHERE si.location = 1 and dis.id = ${params.disciplineID} and si.srfsId = ${params.id}`})
      .then((res) => {
        setDisciplineName(res.data[0]?.name);
        setSrfInstruments(res.data);
        setIafNo(res.data[0]?.iafEafNo);
      })
      .catch((error) => {
        // toast.error("Something Went Wrong!");
      });
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(status,eq,1`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      rows.push([srfInstruments[i].id, srfInstruments[i].instrumentId]);
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    // console.log(rows);
  };

  var pushArray = [];

  for (let i = 0; i < srfInstruments.length; i++) {
    pushArray.push({
      instrumentId: srfInstruments[i].requestedDucName ? srfInstruments[i].requestedDucName : allInstruments.find(
        (element) => element.id === srfInstruments[i].instrumentId
      )?.instrumentName,
      ranges: srfInstruments[i].ranges,
      lc: srfInstruments[i].lc,
      make: srfInstruments[i].make,
      conOfReceipt: srfInstruments[i].conOfReceipt,
      calFrequency: srfInstruments[i].calFrequency,
      accuracy: srfInstruments[i].accuracy,
      model: srfInstruments[i].model,
      serialNumber: srfInstruments[i].serialNumber,
      DUCID: srfInstruments[i].DUCID,
      calPoint: srfInstruments[i].calPoint,
      ConOfDuc: srfInstruments[i].ConOfDuc,
      calFrequency: srfInstruments[i].calFrequency,
    });
  }

  useEffect(() => {
    srfInstrumentsList();
    instrumentsList();
    fetchSrfDetails();
  }, []);

  useEffect(() => {
    getClientId();
  }, [clientID]);

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.4 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.4 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicHeader = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <b style="font-size:19px;">Document Number</b>
          </td>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <b style="font-size:19px;">Issue No. | Date</b>
          </td>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <b style="font-size:19px;">Revision No. | Date </b>
          </td>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <b style="font-size:19px;">Page</b>
          </td>
        </tr>
        <tr>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <span style="font-size:18px;">JRPM-L04-DI-003</span>
          </td>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <span style="font-size:18px;">0707 | 02.05.2022</span>
          </td>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <span style="font-size:18px;">00 | ---</span>
          </td>
          <td style="border:1px solid black;padding:5px;font-family: Calibri, sans-serif;height:35px;text-align:center">
            <span style="font-size:18px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [144, 1, 68, 1],
        filename: `certificate_${params.id}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",          
          compress : true
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

              // border add for contnet
              const borderWidth = 0.6;
              const leftMargin = 17;
              const borderX = leftMargin;
              const borderY = 140; 
              const rightMargin = 595 - leftMargin * 0.95;
              pdf.setLineWidth(borderWidth);
              pdf.rect(borderX, borderY, rightMargin - leftMargin, 640);

      let image = await generateDynamicHeader(i,totalPages);
      pdf.addImage(image, 0, 90, 595, 55);

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 1, 595, 95);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 785, 595, 60);
      }
      if (i === totalPages){
        const text =
          "This SRF is electronically signed and does not required physical signature";
        const textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        {letterHead  && pdf.text(text, x, 788)}
      }
    }

    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${companyData?.companyName}_${serviceReqNumber}.pdf`;
    a.click();

    setDownlaodPdfLoading(false);
  } catch (error) {
    console.error("Error generating PDF:", error);
    setDownlaodPdfLoading(false);
  }
  };
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Electronically Signed" },
              { id: 2, label: "Without  Electronically Signed" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Electronically Signed" />
            )}
            onInputChange={(event, newInputValue) => {
              console.log("newValue : ", newInputValue);
              if (
                newInputValue === "With Electronically Signed" ||
                newInputValue === ""
              ) {
                setElectronicSign(true);
              } else if (newInputValue === "Without  Electronically Signed") {
                setElectronicSign(false);
              }
            }}
          />
        </Grid> */}
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <br />
      <div className="srf" ref={printComponentRef}>
        <div style={{ width: "100%" }} id="header-section"></div>
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            lineHeight: "16px",
          }}
        >
          <div className="row" style={{ lineHeight: "16px" }}>
            <div className="col-3"></div>
            <div className="col-6 mt-2">
              <h6 className="font-bold text-center">
                <b>LEVEL 04: INTERNAL ASSIGNMENT FORM – CALIBRATION (7.1)</b></h6>
            </div>
          </div>
        </div>
        <div
          className="srfFulfilled"
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            borderTop: "none",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "12px",paddingBottom:"5px" }}>
          <table
              style={{ width: "95%", margin: "10px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderRight: "none",
                  }}
                >
                  Company Name
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderRight: "none",
                  }}
                >
                  {companyData?.companyName}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Address
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                  }}
                >
                  {newAddressArray?.address}
                </td>
              </tr>
            </table>
            <table
              style={{ width: "95%", margin: "10px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  CSRF Number
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {serviceReqNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Date of Receipt
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {moment(receiptDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  IAF Number
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {iafNo}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Calibration to be Completed on
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {moment(commitedDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Laboratory
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                  }}
                  colSpan={3}
                >
                  {disciplineName}
                </td>
              </tr>
            </table>
            <table
              style={{ width: "95%", margin: "auto" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    colSpan={11}
                    style={{ padding: "5px", borderBottom: "none",fontSize:"11px" }}
                  >
                    INSTRUMENT DETAILS
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    SI.No
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Description of the Equipment
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Range
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Make
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Model
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Serial Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Identification Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Calibration Points If any
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Requested Due Date
                  </th>
                  <th style={{ padding: "7px" }}>Condition of UUC / Remarks</th>
                </tr>
              </thead>
              {pushArray.length > 0 ? (
                <tbody>
                  {pushArray.map((item, index) => {
                    console.log(item, "item");
                    return (
                      <tr style={{ lineHeight: "13px" }}>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.instrumentId}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.ranges
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.make
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.model}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.serialNumber}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.DUCID}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.calPoint}{" "}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          1
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.calFrequency}
                        </td>
                        <td style={{ padding: "7px", borderTop: "none" }}>
                          {item.ConOfDuc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="11">No items added</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"SRF"} />
      )}
      <ToastContainer />
    </Paper>
  );
}