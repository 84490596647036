import React, { useState } from "react";
import { Modal, Box, Button, Tooltip, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from "@mui/icons-material/Close";

const PrintButtonModal = ({
  printmodalopen,
  handlePrintModalClose,
  selectedRowId,
  location1,
  location2,
}) => {
  // Local state for managing the anchor elements of the menus
  const [anchorElIAF, setAnchorElIAF] = useState(null);
  const [anchorElEAF, setAnchorElEAF] = useState(null);

  // Handlers for managing the IAF menu
  const handleClickIAF = (event) => {
    setAnchorElIAF(event.currentTarget);
  };

  const handleCloseIAF = () => {
    setAnchorElIAF(null);
  };

  // Handlers for managing the EAF menu
  const handleClickEAF = (event) => {
    setAnchorElEAF(event.currentTarget);
  };

  const handleCloseEAF = () => {
    setAnchorElEAF(null);
  };

  return (
    <Modal
      open={printmodalopen}
      onClose={handlePrintModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          border: "2px solid cadetblue",
          borderRadius: "15px",
          padding: "20px",
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{ color: "#001E6C", flexGrow: 1 }}
          >
            <b> Print Form</b>
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handlePrintModalClose}
            sx={{ minWidth: "0px" }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
<br/>
<br/>
<Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Print SRF Form" placement="top">
              <Button
                component={Link}
                to={`/srf/printCsrf/${selectedRowId}`}
                variant="contained"
                size="small"
              >
                SRF Print
              </Button>
            </Tooltip>

            <Tooltip title="Print IAF Form" placement="top">
              <Button variant="contained" size="small" onClick={handleClickIAF}
              endIcon={<KeyboardArrowDownIcon />}
              >
                IAF Print
              </Button>
            </Tooltip>
            <Menu
              anchorEl={anchorElIAF}
              open={Boolean(anchorElIAF)}
              onClose={handleCloseIAF}
            >
              {location1?.map((discipline) => (
                <MenuItem key={discipline.id}>
                  <Link
                    to={`/srf/viewIaf/${selectedRowId}/${discipline.disciplineID}`}
                    style={{ textDecoration: "none" }}
                  >
                    <b>{discipline.name}</b>
                  </Link>
                </MenuItem>
              ))}
            </Menu>

            <Tooltip title="Print EAF Form" placement="top">
              <Button variant="contained" size="small" onClick={handleClickEAF}
               endIcon={<KeyboardArrowDownIcon />}
               >
                EAF Print
              </Button>
            </Tooltip>
            <Menu
              anchorEl={anchorElEAF}
              open={Boolean(anchorElEAF)}
              onClose={handleCloseEAF}
            >
              {location2?.map((discipline) => (
                <MenuItem key={discipline.id}>
                  <Link
                    to={`/srf/viewEaf/${selectedRowId}/${discipline.disciplineID}`}
                    style={{ textDecoration: "none" }}
                  >
                    <b>{discipline.name}</b>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrintButtonModal;
